<template>
    <div class="follow">
		<div class="title">我的关注</div>
        <follow-list v-if="nationalParkData.length != 0" :listData="nationalParkData"></follow-list>
        <not-found v-if="nationalParkData.length == 0"></not-found>
    </div>
</template>
<script>
import {getCellsByFollow} from "../../../api/personService";
import FollowList from "../../../components/followList";
import notFound from "../../../components/notFound";

export default {
  components: {
    notFound,
    FollowList,
  },
  data() {
    return {
      nationalParkData: []
    }
  },
  mounted() {
	       this.getCellsInfo();
       },
       methods: {
           getCellsInfo(){
               getCellsByFollow().then(res=> {
                 const data = res.data;
                 if (data != null) {
                   for (let i = 0; i < data.length; i++) {
                     data[i].span = 8;
                     data[i].isFollow = true;
                     data[i].followText = '取消关注';
                   }
                   this.nationalParkData = data;
                 }

               })
           }
       }

   }
</script>
<style lang="scss">
.follow{
	min-height: 440px;
	padding: 20px;
	box-sizing: border-box;
	.title{
		padding-bottom: 20px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
	}
}
</style>
