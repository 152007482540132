<template>
    <div class="hot-list">
        <el-row :gutter="12">
            <el-col :key="index" :span="list.span" v-for="(list, index) of listData">
                <div class="list">
                    <img :src="list.ydlj" @click="detail(list.id)" @mouseenter="enter(list,$event)"
                         @mouseleave="leave(list,$event)"
                         class="pic"/>
                    <el-row>
                        <el-col :span="16">
                            <span :title="list.djdymc" class="title">{{list.djdymc}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span @click="handleFollow(list,index)">
                                <i :class="[list.isFollow? 'icon-follow' : 'icon-unfollow']" class="icon"></i>
                                {{list.followText==null?'关注':list.followText}}
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <p class="fl">
                                <i class="icon icon-type"></i>
                                <span>{{list.zylx}}</span>
                            </p>
                        </el-col>
<!--                        <el-col :span="14">-->
<!--                            <p class="fr">-->
<!--                                <i class="icon icon-area"></i>-->
<!--                                <span v-if="(list.mj/100)>10000">{{(list.mj/(100*10000))}}万平方公里</span>-->
<!--                                <span v-if="(list.mj/100)<=10000">{{list.mj/100}}平方公里</span>-->
<!--                            </p>-->
<!--                        </el-col>-->
                        <el-col :span="24">
                            <p class="fl">
                                <i class="icon icon-location"></i>
                                <span>{{list.dq}}</span>
                            </p>
                        </el-col>
<!--                        <el-col :span="12">-->
<!--                            <p class="fr">-->
<!--                                <i class="icon icon-date"></i>-->
<!--                                <span>{{list.djsj}}</span>-->
<!--                            </p>-->
<!--                        </el-col>-->
                        <el-col>
                            <p class="fl" v-if="list.lxztlx === '1'">
                              <i class="icon icon-dbxszt"></i>
                              <span>所有者职责履行主体: {{list.syzzzdllxzt}}</span>
                            </p>
                            <p class="fl" v-else-if="list.lxztlx === '2'">
                                <i class="icon icon-dbxszt"></i>
                                <span>所有者职责代理履行主体: {{list.syzzzdllxzt}}</span>
                            </p>
                            <p class="fl" v-else>
                              <i class="icon icon-dbxszt"></i>
                              <span>未知: {{list.syzzzdllxzt}}</span>
                            </p>

                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>

    import {checkCollection} from "../api/personService";

    export default {
        props: {
            listData: [],
        },

        data() {
            return {
                isFollow: true
            }
        },
        watch: {
            'listData': {
                handler(val) {
                    if (val != undefined) {
                        this.lists = val
                    }
                },
                immediate: true,
            }
        },
        updated(){
            this.setFollowState();
        },
        methods: {
            enter(list, e) {
                if (list.dtlj != null) {
                    // e.target.src = "http://qbl3y5oye.bkt.clouddn.com/loading.gif"
                    // var newImg = new Image()
                    // newImg.src = list.dtlj
                    // newImg.onload = () => { // 图片加载成功后把地址给原来的img
                    //     e.target.src = newImg.src
                    // }
                    e.target.src = list.dtlj;
                }
            },
            leave(list, e) {
                e.target.src = list.ydlj;
            },

            handleFollow(item,index) {
                const param = {graphId: item.id,type:'follow'};
                checkCollection(param).then(res=>{
                    if(res.data ==='0'){
                        this.listData[index].isFollow = false;
                        this.listData[index].followText = '关注';
                    }else if(res.data ==='1'){
                        this.listData[index].isFollow = true;
                        this.listData[index].followText = '取消关注';
                    }else {
                        this.$message({
                            message: res.data,
                            type:'warning'
                        })
                    }
                })
            },
            /**
             * 根据当前单元号跳转
             * @param currentId
             */
            detail(currentId) {
                this.$router.push({
                    name: 'infoDetail',
                    query: {
                      zrzyDyId: currentId
                    }
                })
            },
        }
    }
</script>
<style lang="scss">
    .hot-list {
        .list {
            padding: 4px;
            margin-bottom: 12px;
            cursor: pointer;
            background-color: #fff;
            border: 1px solid rgba(217, 217, 217, 1);

            .pic {
                width: 100%;
                height: 160px;
                margin-bottom: 10px;
            }

            .el-row {
                font-size: 14px;
                color: #333;
                padding: 0 4px;

                .el-col {
                    max-height: 34px;
                    line-height: 34px;
                    overflow: hidden;

                    .title {
                        font-size: 18px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    .icon {
                        float: left;
                        width: 18px;
                        height: 18px;
                        margin: 8px 5px 0 0;
                        background: center no-repeat;
                    }

                    .icon-type {
                        background-image: url(../assets/images/icon_type.png);
                    }

                    .icon-area {
                        background-image: url(../assets/images/icon_area.png);
                    }

                    .icon-location {
                        background-image: url(../assets/images/icon_location.png);
                    }

                    .icon-date {
                        background-image: url(../assets/images/icon_date.png);
                    }
                    .icon-dbxszt {
                        background-image: url(../assets/images/icon_dbxszt.png);
                    }

                    .icon-follow {
                        background-image: url(../assets/images/icon_follow.png);
                    }

                    .icon-unfollow {
                        background-image: url(../assets/images/icon_unfollow.png);
                    }
                }
            }
        }
    }

</style>
